<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/m/privacy.vue') //代码切割
const _pc = () => import('@/pages/pc/privacy.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "domainConfig"
    ]),
    title () {
      return `Privacy Policy | ${this.domainConfig['meta'].title}`
    },
    description () {
      return `${this.domainConfig['meta'].des}`
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          name: 'title',
          content: this.title,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    }
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },

};
</script>
